<template>
    <el-dialog title='家谱图' class="dialogBox" :visible.sync="showDialog" width='1200px' :before-close="handleClose">
        <div class="tu_box">
            
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="handleClose">关 闭</el-button>
        </span>
    </el-dialog>
</template>

<script>
import {selectTreeUserByClanId} from "@/api/pc.js";
export default {
  name: "dialogBox",
  props:{
    showvisible:{
        type:Boolean,
        default:false
    },
  },
  data() {
    return {
        showDialog:false,
        activityData:[],
        levelInfoFilter:[],
    };
  },
  watch:{
    showvisible(val,lod){
        this.showDialog = val;
    }
  },
  components: {},
  async mounted() {
    var res = await selectTreeUserByClanId({clanId:43});
    if(res.code==200){
        this.activityData = [];
		this.levelInfoFilter = [];
        var listarr = this.setDataList(res.data);
        // console.log(listarr)
    }
  },
  methods: {
    // 关闭
    handleClose(){
        this.$emit('handleClose')
    },
    // 设置数据
    setDataList(val){
        var arr = [];
        var numPid = [];
        val.forEach((v,inx)=>{
                // 当它是第一辈分的时候 给一个顶级标签
                if(inx==0){
                    arr.push(...v.clanUserResultVOList.map(t=>{return {...t,index:v.index,children:[],name:t.firstName,disabled:false}}));
                    // 获取顶级标签的pid
                    numPid.push(...(v.clanUserResultVOList.map(t=>{return t.clanUserId})))
                    // console.log(arr)
                    // console.log(numPid)
                }else{
                    v.clanUserResultVOList.forEach(v2=>{
                        if(v2.parentClanUserId){
                            if(numPid.indexOf(v2.parentClanUserId)>-1){
                                if(find(arr , v2.parentClanUserId).length>0){
                                    find(arr, v2.parentClanUserId)[0].children.push({...v2,index:v.index,children:[],name:v2.firstName,disabled:false})
                                }
                                if(numPid.indexOf(v2.clanUserId)==-1){
                                    numPid.push(v2.clanUserId)
                                }
                            }else{}
                        }else{
                            let objItem = v.clanUserResultVOList.filter(t=>{return !t.parentClanUserId})[0];
                            arr.push({...objItem,index:v.index,children:[],name:v2.firstName,disabled:false});
                            // 获取顶级标签的pid
                            numPid.push(...(v.clanUserResultVOList.map(t=>{return t.clanUserId})))
                        }
                    })
                }
            this.levelInfoFilter.push({
                index:v.index,
                manNum:v.clanUserResultVOList.filter(v2=>{return v2.sex==1}).length,
                womanNum:v.clanUserResultVOList.filter(v2=>{return v2.sex!=1}).length,
            })	
        })
            function find(arr, id) {
                if (arr == null) return null;
                for (let obj of arr) {
                    if (obj.clanUserId == id) {
                        return collect(obj);
                    }
                    let ret = find(obj.children, id);
                    if (ret) return ret;
                }
                return null;
            }
        
            function collect(obj) {
                let ret = [obj];
                if (obj.children) {
                    for (let o of obj.children) {
                        ret = [...ret, ...collect(o)]
                    }
                }
                return ret;
            }
    
        return arr
    }
    
  },
};
</script>

<style lang='scss' scoped>
.dialogBox ::v-deep .el-dialog{
    margin-top: 8vh !important;
    height: 84vh;
}
.dialogBox ::v-deep .el-dialog__body{
    height: calc(100% - 100px);
}
.tu_box{
    width: 100%;
    height: 100%;
    overflow: auto;
}
.el-dialog__footer{
    text-align: center;
    border-top: 1px solid #ebebeb;
    button{
        width: 70px;
    }
}
</style>