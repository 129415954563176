import request from "@/utils/requestmobile.js"

var uploadImage = '';     // npm run build
// 执行 npm run serve 
if( process.env.NODE_ENV != 'production' && process.env.NODE_ENV != 'prod' ) {
    uploadImage = '';
}

class UploadFiles{
    constructor(){
        // 参数解析
        Object.assign(this,...arguments);
        return this.init();
    }
    init(){
        // 参数
        let fileObj = this.file;
        let source = this.source;
        let currentProjectId = this.projectId;
        return new Promise((resolve,reject)=>{
            // 参数验证
            if(!fileObj){
                reject({message:'fileObj参数不能为空',flag:false});
                return;
            }
            // if(Object.keys(fileObj).length==0){
            //     reject({message:'fileObj参数必须是个file对象',flag:false});
            //     return;
            // }
            // if(!source){
            //     reject({message:'source参数不能为空',flag:false});
            //     return;
            // }
            // if(source !=2){
            //     reject({message:'source参数类型错误',flag:false});
            //     return;
            // }
            var formData = new FormData();
            formData.append('file',fileObj);
            //formData.append('source',source);
            // console.log(formData,"-------w212-------------------------------")

            // 发送请求
            request({
                url:`${uploadImage}/common/upload`,
                method:'post',
                data:formData,
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                transformRequest: [function (data) {
                    return data;
                }],
            }).then(res=>{
                // console.log(res,"--------------------------------------")
                if(res.code == 200 && res){
                    resolve(res);

                }
            }).catch(error=>{
                reject({message:error,flag:false});
            })
        })
    }
}

window.$UploadFile = UploadFiles;
