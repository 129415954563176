<template>
    <div class='indexHome'>
        <common-table :searchModel="searchModel" :searchKey='searchKey' :listModel="listModel" :pageInfo="pageInfo"
            :listKey="listKey" :isLoading="isLoading" :tableOffset="209" v-on:on-sort-change='propList' :hidePage="true"
            v-on:on-search="onSearch()" v-on:on-page="onPage" v-on:on-page-size="onPageSizeChange"
            v-on:on-reset-search="onResetSearch">
            <!-- <template slot="form-item-btns">
            <el-button size="small" :loading="isLoading" icon="el-icon-plus" @click="addLevel" type="primary">添加</el-button>
        </template> -->
            <template slot="tab-front-column">
                <el-table-column label="序列" type="index" width='50'></el-table-column>
            </template>
            <template slot="tab-edit">
                <el-table-column label="操作" align="center" width="140">
                    <template slot-scope="scope">
                        <!-- <el-button @click="setLevel(scope.row)" type="text">编辑</el-button>
                    <el-button @click="delLevel(scope.row)" type="text">删除</el-button> -->
                        <el-button @click="openModel(scope.row)" type="text">查看</el-button>
                        <el-button @click="setNum(scope.row)" type="text">调整创建家谱次数</el-button>
                    </template>
                </el-table-column>
            </template>
        </common-table>
        <el-dialog title="提示" :visible.sync="dialogVisible" width="600px" :before-close="handleClose">
            <div class="cont">
                <div class="cont-top">
                    <img class="cont-item-img" :src="dialogObj.avatar" alt="">
                </div>
                <div class="cont-item">
                    <span> 用户名： </span>
                    <span> {{ dialogObj.userName }} </span>
                </div>
                <div class="cont-item">
                    <span> 用户手机号： </span>
                    <span> {{ dialogObj.phoneNum }} </span>
                </div>
                <div class="cont-item">
                    <span> 用户身份证号： </span>
                    <span> {{ dialogObj.idCard ? dialogObj.idCard : '' }} </span>
                </div>
                <div class="cont-item">
                    <span> 创建时间： </span>
                    <span> {{ dialogObj.idCard ? dialogObj.idCard : '' }} </span>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="dialogVisible = false">关 闭</el-button>
            </span>
        </el-dialog>
        <el-dialog title="调整创建家谱次数" :visible.sync="openNum" width="400px" :before-close="beforeClose" center>
            <div class="cont">
                <el-form label-width="100px">
                    <el-form-item label="请输入：">
                        <el-input-number v-model="markGenealogyNum" :controls="false" controls-position="right" style="width: 100%;"></el-input-number>
                    </el-form-item>
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="beforeClose">关 闭</el-button>
                <el-button type="primary" @click="addNumserve">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import CommonTable from "@/views/components/CommonTable/CommonTable.vue";
import shuputu from "@/views/components/shuputu";
import { userList, removeCageGory, addClanAlbum , updateUserMarkGenealogyNum} from "@/api/pc.js";
export default {
    name: 'IndexHome',
    data() {
        return {
            // 弹窗
            dialogVisible: false,
            dialogObj: { avatar: require('@/assets/images/toux.png') },
            isLoading: false,
            showvisible: false,
            // 添加分类弹窗
            showTitle: '',
            listModel: [],
            searchKey: [
                {
                    type: 'input',
                    key: 'userName',
                    label: "用户名称",
                    width: 200
                }
            ],
            searchModel: {
                userName: ''
            },
            pageInfo: { pageNum: 1, pageSize: 10, total: 0 },
            listKey: [
                { key: 'userNo', label: '用户ID' },
                { key: 'userName', label: '用户名称' },
                { key: 'sexName', label: '用户性别' },
                { key: 'phoneNum', label: '用户手机号' },
                { key: 'createdTiemr', label: '创建时间' },
                { key: 'markGenealogyNum', label: '操作次数' },
            ],
            // 分类名称
            classifyName: '',
            // 分类ID
            classifyID: '',
            addForm: {
                albumName: '',
                pics: [],
            },
            // 图片展示
            srcList: [],

            // 设置次数
            markGenealogyNum:'',
            // 设置次数弹窗
            openNum: false,
            openNumObj:{}

        }
    },
    components: { CommonTable, shuputu },
    created() {
        this.getdata();
    },
    methods: {
        // 关闭
        beforeClose(){
            this.openNum=false;
            this.getdata();
        },
        // 打开设置家谱次数
        setNum(val) {
            this.markGenealogyNum= val.markGenealogyNum;
            this.openNumObj  = val;
            this.openNum = true;
        },
        addNumserve(){
            let params = {userId:this.openNumObj.userId,markGenealogyNum:this.markGenealogyNum}
            updateUserMarkGenealogyNum(params).then(res=>{
                if(res.code==200){
                    this.getdata();
                    this.$message.success('操作成功！')
                    this.openNum = false;
                }else{
                    this.$message.warning(res.msg);
                }
            }).catch(err=>{this.$message.warning(err.msg)})
        },
        openModel(val) {
            this.dialogObj = val;
            this.dialogObj.avatar = require('@/assets/images/toux.png');
            this.dialogVisible = true;
        },

        // 点击排序
        propList() { },
        // 点击搜索
        onSearch() {
            this.pageInfo.pageNum = 1;
            this.getdata();
        },
        // 点击分页
        onPage(val) {
            this.pageInfo.pageNum = val;
            this.getdata();
        },
        // 点击分页的数量
        onPageSizeChange() { },
        // 点击重置
        onResetSearch() { },
        // 获取数据
        getdata() {
            this.isLoading = true;
            var formData = new FormData();
            formData.append('userName', this.searchModel.userName)
            formData.append('pageNum', this.pageInfo.pageNum)
            formData.append('pageSize', this.pageInfo.pageSize)
            userList && userList(formData).then(res => {
                // console.log(res)
                if (res && res.code == 200) {
                    // console.log(res)
                    res.data.rows = res.data.rows.map(it => { return { ...it, createdTiemr: '2023-01-01 12:00:00', sexName: '男' } })
                    this.listModel = res.data.rows || [];
                    this.pageInfo.total = res.data.total || 0;
                } else {
                    // console.log(res)
                }
                this.isLoading = false;
            }).catch(err => { this.isLoading = false;
            })
        },
        // 添加分类
        addLevel() {
            this.showTitle = '添加分类';
            this.showvisible = true;
        },
        // 编辑分类
        setLevel(val) {
            this.showTitle = '编辑分类';
            this.classifyName = val.classifyName;
            this.showvisible = true;
        },
        // 删除分类
        delLevel(val) {
            let params = { categoryId: val.id };
            removeCageGory && removeCageGory(params).then(res => {
                // console.log(res)
                if (res && res.code == 200) {
                    // console.log(res)
                } else {
                    // console.log(res)
                }
            }).catch(err => { // console.log(err) 
            })
        },
        // 关闭弹窗
        handleClose() {
            this.showvisible = false;
            this.dialogVisible = false;
            this.openNum = false;
        },
        // 保存分类
        serve() {
            let params = { categoryId: '1', categoryName: this.classifyName };
            var formData = new FormData();
            formData.append('categoryId', '1')
            formData.append('albumName', this.classifyName)
            addClanAlbum && addClanAlbum(params).then(res => {
                // console.log(res)
                if (res && res.code == 200) {
                    // console.log(res)
                } else {
                    // console.log(res)
                }
            }).catch(err => { // console.log(err) 
            })
            this.showvisible = false;
        },
        // 打开图片
        openimg(val, list) {
            // console.log(list.map(it => { return it.fileUrl }))
            this.srcList = list.map(it => { return it.fileUrl })
        },
    }
}
</script>
<style lang='scss' scoped>
.indexHome {
    width: 100%;
    height: 100%;

}

.contbox {
    width: 100%;
    padding: 20px 0;

    .uploadItemBox {
        width: 100%;
        height: 300px;
        overflow-y: auto;
    }
}

.cont {
    width: 100%;
    padding: 2px;
    background-color: #fff;

    .cont-top {
        width: 100%;
        overflow: hidden;
        text-align: center;

        .cont-item-img {
            width: 100px;
            height: 100px;
            display: inline-block;
        }
    }

    .cont-item {
        width: 100%;
        height: 40px;
        margin-top: 10px;
        line-height: 40px;
        font-size: 20px;
        background-color: #fff;
        color: #333;
        text-align-last: left;
        display: flex;

        span:first-child {
            font-size: 16px;
            width: 120px;
            // text-align: left;
        }
    }
}</style>