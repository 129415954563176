<template>
    <div class="flex_col">
        <!--搜索栏-->
        <div v-if="searchKey.length > 0" style="padding: 0px 10px 10px ;border-radius: 5px;float:right">
            <slot name="form">
                <el-form :inline="true" :model="searchModel" class="tabelForm" :rules="searchRules" ref="ruleForm" :label-width="labelWidth" style="text-align:left ;">
                    <template v-for="(item,index) in searchKey">
                        <el-form-item style="margin-right: 20px;margin-bottom:0;" :key="index" :label="item.label+'：'" :prop="item.key">
                            <!-- input -->
                            <el-input :disabled="item.disabled" v-if="item.type == 'input'" v-model="searchModel[item.key]" clearable
                                    prefix-icon="el-icon-search" :type="item.input_type" :style="{ width: item.width+'px'}"
                                    :placeholder="'请输入'+item.label">
                            </el-input>

                            <!-- select -->
                            <el-select :disabled="item.disabled" v-if="item.type == 'select'" v-model="searchModel[item.key]" filterable clearable :style="{width: item.width+'px'}"
                                    placeholder="请选择" :multiple="item.multiple" @change="item.onChange">
                                <el-option v-for="(subItem,incx) in searchModel[item.valueKey]" :key="subItem.value + incx" :label="subItem.value" :value="subItem.key">
                                    <div class="column">
                                        <div class="name">{{subItem.value}}</div>
                                        <div class="desc">{{subItem.label}}</div>
                                    </div>
                                </el-option>
                            </el-select>

                            <!-- datepick -->
                            <el-date-picker :disabled="item.disabled" v-if="item.type=='datetimepicker'" v-model="searchModel[item.key]" value-format="yyyy-MM-dd HH:mm:ss"
                                            :type="item.date_type" :picker-options="pickerOptions" range-separator="至"
                                            start-placeholder="开始日期" end-placeholder="结束日期" align="right" @change="item.onChange">
                            </el-date-picker>

                            <!-- cascader -->
                            <el-cascader :disabled="item.disabled" v-if="item.type == 'cascader'" placeholder="请选择" v-model="searchModel[item.key]" :options="searchModel[item.valueKey]" :props="props"
                                        clearable show-all-levels filterable :style="{width: item.width+'px'}" @change="item.onChange">
                            </el-cascader>
                        </el-form-item>
                    </template>

                    <slot name="form-item">
                        <!--自定义form表单数据-->
                    </slot>

                    <el-button size="small" v-if='showSerachBtn' :loading="isLoading" icon="el-icon-search" @click="submitForm('ruleForm')" type="primary">搜索</el-button>
                    <slot name="form-item-btns">
                        <!--自定义form表单数据-->
                    </slot>
                </el-form>
            </slot>
        </div>

        <div style="margin: 12px 0px 0px 0px;" v-show='showBtn'>
            <slot name="btn-content">
                <!--自定义子button-->
            </slot>
        </div>

        <!--列表栏-->
        <div class="hide_score">
            <el-table ref="table" :show-summary="tabelSummary" row-key="id" :data="listModel"
                      :height="tableWidth" stripe style="width: 100%;margin-top: 10px;" fit :header-cell-style="{background:'#eef1f6',color:'#353535'}"
                      v-loading="isLoading" @sort-change="mysortChange" @selection-change="handleSelectionChange"
                      @current-change="handleCurrentChange">

                <slot name="tab-select">
                    <el-table-column v-if="isSelectTable" align='center' show-overflow-tooltip type="selection" width="55" fixed></el-table-column>
                </slot>
<!--                    <el-table-column type="index" width="80" fixed></el-table-column>-->
                <slot name="tab-front-column">
                    <!--头部自定义列-->
                </slot>
                <template v-for="(item,index) in listKey" >
                    <el-table-column :property="item.key" :key="index" :label="item.label" :min-width="item.width" :width="item.width" :sortable="item.sortable" align='center' show-overflow-tooltip> </el-table-column>
                </template>

                <slot name="tab-column">
                    <!--尾部自定义列-->
                </slot>

                <slot name="tab-edit">
                    <!--自定义操作-->
                </slot>
                
                <!-- 添加缺省图 -->
                 <template slot="empty">
                     <slot name="tab-empty">
                        <!--自定义操作-->
                    </slot>
                </template>
            </el-table>
        </div>

        <!--分页-->
        <slot name="page">
            <el-row type="flex" justify="space-between" :style="tabelSummary?'margin-top: 45px;':'margin-top: 20px;'">
                <div></div>
                <div>
                    <el-pagination v-if="hidePage&&pageInfo.total>0" background layout="total,prev,pager,next"
                                   :current-page="pageInfo.pageNum" :page-size="pageInfo.pageSize" :total="pageInfo.total" :page-sizes="[20,50,100,200]"
                                   @current-change="onPage" @size-change="onSizeChange">
                    </el-pagination>
                </div>
                <div></div>
            </el-row>
        </slot>
    </div>
</template>
<script>
export default {
    data(){
        return {
            pickerOptions :{
                shortcuts: [{
                    text: '最近一周',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近一个月',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近三个月',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                        picker.$emit('pick', [start, end]);
                    }
                }]
            },
            tableWidth:100, //table宽度
            props : {value: 'key',label: 'value',expandTrigger:'hover'}, //多级联动配置
            selectList : [], //多选的数据
            currentRow: 1, //单选 当前选中的行
        }
    },
    props:{
        // loading
        isLoading:{
            type:Boolean,
            default:false
        },
        // 是否显示合计行
        tabelSummary:{
            type:Boolean,
            default:false
        },
        // 搜索的按钮是否展示， 默认展示
        showSerachBtn:{
            type:Boolean,
            default:true
        },
        // label宽度，组件对齐用此参数
        labelWidth:{
            type:String | Number,
            default:''
        },
        // 搜索的变量
        searchModel:{
            type: Object,
            default:()=>{
                return {};
            }
        },
        // 搜索的表单的校验
        searchRules:{
            type: Object,
            default:()=>{
                return {};
            }
        },
        // 表格的列表
        listModel:{
            type: Array,
            default:()=>{
                return [];
            }
        },
        // 表格数据的表头列表
        listKey:{
            type: Array,
            default:()=>{
                return [];
            }
        },
        // 分页对象
        pageInfo:{
            type: Object,
            default:()=>{
                // pageNum 当前页
                // pageSize 每页的数量
                // size 当前页的数量
                // total 总数
                // pages 总页数
                return {pageNum:1,pageSize:10,size:0,total:0,pages:1};
            }
        },
        // 搜索的数组
        searchKey:{
            type: Array,
            default:()=>{
                return [];
            }
        },
        // table偏移量
        tableOffset:{
            type:String | Number,
            default:''
        },
        // 列表是否可拖动
        isOnDrag:{
            type:Boolean,
            default:false
        },
        // 列表是否可选择
        isSelectTable:{
            type:Boolean,
            default:false
        },
        // 是否显示分页
        hidePage:{
            type:Boolean,
            default:false
        },
        // 显示中间的定义btn
        showBtn:{
            type:Boolean,
            default:false
        },
    },
    mounted(){
        this.setTableHeight();
        window.addEventListener('resize', () =>{ this.setTableHeight();}, false)
    },
    methods:{
        /**
         * 选中行数
         */
        setCurrentRow(row) {
            // @ts-ignore
            this.$refs.table.setCurrentRow(row);
        },
        // 设置高度
        setTableHeight(){
            this.$nextTick(() => {
                this.tableWidth =  document.body.clientHeight - this.tableOffset;
            })
        },

        // 点击了多选
        handleSelectionChange(selection) {
            this.$emit("handleSelectionChange",selection)
            // this.selectList = selection;
        },
        
        // 选择了单行
        handleCurrentChange(val) {
            this.currentRow = val;
            this.$emit('on-current-change',this.currentRow);
        },

        // 点击排序
        mysortChange(val) {
            this.$emit('on-mysort-change',val);
        },

        // 点击搜索
        submitForm(formName) {
            // @ts-ignore
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.onSearch();
                } else {
                    // //// console.log('error submit!!');
                    return false;
                }
            });
        },

        // 重置表单
        // onResetForm(formName) {
        //     this.$confirm('确定要重置吗?', '提示', {
        //         confirmButtonText: '确定',
        //         cancelButtonText: '取消',
        //         type: 'warning'
        //     }).then(() => {
        //         this.resetForm(formName);
        //     }).catch(() => {
        //     });
        // },

        // 重置表单
        // resetForm(formName) {
        //     // @ts-ignore
        //     this.$refs[formName].resetFields();
        //     this.onResetSearch();
        // }

        // 点击了搜索
        onSearch() {
            this.$emit('on-search');
        },

        // 点击了搜索
        onResetSearch() {
            this.$emit('on-reset-search');
        },

        // 点击了分页
        onPage(page) {
            this.$emit('on-page',page);
        },

        // 点击了分页 - 分页数量调整
        onSizeChange(val) {
            this.$emit('on-page-size',val);
        },

        // 点击了分页 - 分页数量调整
        onSizeChange(val) {
            this.$emit('on-page-size',val);
        },

    }
}
</script>
<style>
.el-tooltip__popper{
    max-width: 300px !important;
}
.tabelForm .el-form-item:first-child{
    margin-bottom: 10px !important;
}
</style>
