<template>
    <div class='indexHome'>
        <common-table :searchModel="searchModel" :listModel="listModel" :pageInfo="pageInfo" :listKey="listKey" :isLoading="isLoading"
            :tableOffset="215" v-on:on-sort-change='propList'
            v-on:on-search="onSearch()" v-on:on-page="onPage" v-on:on-page-size="onPageSizeChange"
            v-on:on-reset-search="onResetSearch" >
            <template slot="tab-front-column">
                <el-table-column label=" " width='50'></el-table-column>
                <el-table-column label="头像/昵称"  width='180'> 
                    <template slot-scope="scope">
                        <div class="flex_row">
                            <img :src="scope.row.avatar_url" style="width:40px;height:40px;border-radius: 50%;" alt="">
                            <div style="width:calc(100% - 46px);margin-left:6px;">
                                <p class="fontSize_14 ellipsis-1">{{scope.row.nick_name}} </p>
                                <p class="fontSize_14 color_hui">{{scope.row.spread_level_id | levelId}} </p>
                            </div>
                        </div>
                    </template>
                </el-table-column>
            </template>
        <!-- <template slot="tab-edit">
            <el-table-column label="操作" align="center" width="140">
                <template slot-scope="scope">
                    <el-button @click="setLevel(scope.row)" type="text">调整</el-button>
                </template>
            </el-table-column>
        </template> -->
    </common-table>
    </div>
</template>
<script>
import CommonTable from "@/views/components/CommonTable/CommonTable.vue";
import { userList } from '@/api/pc.js'
export default {
    name:'IndexHome',
    data(){
       return{
        // loading 变量
        isLoading:false,
        // 分页
        pageInfo:{pageNum:1,pageSize:10,total:0},
        // 列表
        listModel:[],
        // 表头
        listKey:[
            {key:'userName',label:'姓名'},
            {key:'phoneNum',label:'手机号码'},
            {key:'idCard',label:'身份证',width:160},
            {key:'userNo',label:'云谱号'},
            {key:'userType',label:'用户类型'},
        ],
        // 搜索数组
        searchKey:[
            {
                type:'input',
                key:'userName',
                label:'用户姓名',
                input_type:'input',
                width:140,
            },
            {
                type:'input',
                key:'phoneNum',
                label:'手机号码',
                input_type:'input',
                width:140,
            },
            {
                type:'datetimepicker',
                date_type:'datetimepicker',
                key:'startTime',
                label:'创建时间',
                width:140,
                onChange:this.onChangeItemr()
            },
            
        ],
        // 搜索对象
        searchModel:{
            userName:'',
            phoneNum:'',
            itemrArr:''
        },
       }
    },
    components: { CommonTable },
    mounted(){
        cosole.log('1')
        this.userList()
    },
       
    methods:{
        // 点击排序
        propList(){},
        // 点击搜索
        onSearch(){},
        // 点击分页
        onPage(){},
        // 点击分页的数量
        onPageSizeChange(){},
        // 点击重置
        onResetSearch(){},
    }
}
</script>
<style lang='scss' scoped>
.indexHome{
    width: 100%;
    height: 100%;
    
}
</style>