<template>
  <div class="login">
    <el-form ref="loginForm" :model="loginForm" :rules="loginRules" label-position="left" label-width="0px" class="login-form">
      <h3 class="title">
        盛昌云谱后台管理系统
      </h3>
      <el-form-item prop="userName">
        <el-input v-model="loginForm.userName" type="text" auto-complete="off" placeholder="账号" prefix-icon="el-icon-user"></el-input>
      </el-form-item>
      <el-form-item prop="password">
        <el-input v-model="loginForm.password" 
          type="password" placeholder="密码"
          prefix-icon="el-icon-lock" 
          @keyup.enter.native="handleLogin" 
          show-password
        ></el-input>
      </el-form-item>
      <el-form-item style="width:100%;">
        <el-button :loading="loading" size="medium" type="primary" style="width:100%;" @click.native.prevent="handleLogin">
          <span v-if="!loading">登 录</span>
          <span v-else>登 录 中...</span>
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import {login} from '@/api/pc.js'
import Cookies from 'js-cookie'
export default {
  name: "Login",
  data() {
    return {
        userName:'',
        password:'',
        loginForm: {
          userName: '',
          password: '',
          // userName: 'admin',
          // password: 'admin123',
          code: '',
          uuid: ''
        },
        loginRules: {
          userName: [{ required: true, trigger: 'blur', message: '用户名不能为空' }],
          password: [{ required: true, trigger: 'blur', message: '密码不能为空' }]
          // code: [{ required: true, trigger: 'change', message: '验证码不能为空' }]
        },
        loading: false,
    };
  },
  methods:{
    handleLogin(){
        if(this.loginForm.userName==""){return this.$message.warning('请输入账号')};
        if(this.loginForm.password==""){return this.$message.warning('请输入密码')};
        login({loginName:this.loginForm.userName,password:this.loginForm.password}).then(res=>{
          // console.log(res)
            if(res.code==200){
                Cookies.set('zuji-token',res.data.token);
                Cookies.set('zuji-userInfo',JSON.stringify(res.data));
                sessionStorage.zujiUserInfo = JSON.stringify(res.data);
                this.$router.push({path:'/'})
            }else{
              this.$message.warning(res.msg)
            }
        }).catch(err=>{this.$message.warning(err.msg)})
    }
  }
};
</script>

<style rel="stylesheet/scss" lang="scss">
  .login {
      width: 100%;
      height: 100%;
      background: #ffffff url("@/assets/images/background.jpg") no-repeat;
      background-size: cover;
      background-attachment: fixed;
      background-repeat: no-repeat;
      background-position: 0 0;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
  }

  .title {
    margin: 0 auto 30px auto;
    text-align: center;
    color: #707070;
  }

  .login-form {
    border-radius: 6px;
    background: #ffffff;
    width: 385px;
    padding: 25px 25px 5px 25px;
    text-align: left;
    .el-input {
      height: 38px;
      input {
        height: 38px;
      }
    }
    .input-icon{
      height: 39px;width: 14px;margin-left: 2px;
    }
  }
  .login-tip {
    font-size: 13px;
    text-align: center;
    color: #bfbfbf;
  }
  .login-code {
    width: 33%;
    display: inline-block;
    height: 38px;
    float: right;
    img{
      cursor: pointer;
      vertical-align:middle
    }
  }
</style>