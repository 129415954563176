import axios from 'axios'
// import Qs from 'qs'
import Cookies from 'js-cookie'

//设置超时
axios.defaults.timeout = 15000;

// 创建一个axios实例
const service = axios.create({
  transformRequest: [function (data) {
    return JSON.stringify(data)
  }]
});

// 添加请求拦截器
service.interceptors.request.use(config => {
  // // console.log("我的请求头部")
  // // console.log(config)
  // // 是否需要设置 token
  const isToken = (config.headers || {}).isToken === false
  // 是否需要防止数据重复提交
  const isRepeatSubmit = (config.headers || {}).repeatSubmit === false
  if ( Cookies.get('zuji-token') && !isToken) {
    config.headers['Authorization'] = Cookies.get('zuji-token') // 让每个请求携带自定义token 请根据实际情况自行修改
  }
  return config;
}, error => {
  // 请求错误做些事
  return Promise.reject(error);
});

// 添加相应拦截器
service.interceptors.response.use(response => {
  const res = response.data;
  if(res.code == 401){//重新登录
    Cookies && Cookies.remove('zuji-token');
    // this.$router.push('/login')
    window.location.href = '/login'
    // this.$router.push('/login')
  }
  if(res.code == 501){//重新登录
    return window.$message.warning('试用期限已过，请联系开发团队!')
  }
  return res;
}, (error) => {
  if(error && error.response && error.response.status && error.response.status == '4001'){//重新登录
    Cookies && Cookies.remove('zuji-token');
  }
  console.log(error,'我的错误信息')
  if(typeof(error)=='string'){
    return Promise.reject({code:500,msg:'请求失败，请刷新页面后在试'});
  }
  // 返回接口的错误信息
  return Promise.reject(error);
})

export default service
